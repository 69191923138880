import React, { useContext, useState, useEffect } from "react";
import { graphql, navigate } from "gatsby";
import { Router } from "@reach/router";
import Elm from "react-elm-components";
import moment from "moment";

import { CurrentUserContext } from "../providers/auth";
import {
    LeafletProvider,
    LeafletWebComponentsContext,
    TooltipProvider,
    TooltipWebComponentsContext,
} from "../providers/custom-elements";
import { FileResourceAccessProvider } from "../providers/file-resource-access";
import { MapEditState } from "../helpers/mapEditState";

import "../styles/components/leaflet-map.css";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Loading from "../components/loading";
import SignInAgain from "../components/sign-in-again";
import MeshStepProgressBar from "../components/progress-bar";
import { ErrorStickyTop } from "../components/error";
import { ConfirmBox } from "../components/confirm";

import estimate from "../js/estimateCells";
import { getMeshspecs, copyMeshspecs, deleteMeshspec } from "../js/cpasApi";

import EditApp from "../ElmApp/Page/MeshSpec/Editor.elm";
import PreviewApp from "../ElmApp/Page/MeshSpec/Preview.elm";

const Routing = (props) => {
    let {
        CPAS_API_SERVICE_URI,
        CACHE_MESH_SPEC_KEY,
    } = props.data.site.siteMetadata;

    return (
        <Layout>
            <LeafletProvider>
                <TooltipProvider>
                    <Router>
                        <ListPage
                            path="meshspec"
                            CPAS_API_SERVICE_URI={CPAS_API_SERVICE_URI}
                        />
                        <EditPage
                            path="meshspec/:meshSpecId/edit"
                            CPAS_API_SERVICE_URI={CPAS_API_SERVICE_URI}
                            CACHE_MESH_SPEC_KEY={CACHE_MESH_SPEC_KEY}
                        />
                        <EditPage
                            path="meshspec/new/fromUpload"
                            CPAS_API_SERVICE_URI={CPAS_API_SERVICE_URI}
                            CACHE_MESH_SPEC_KEY={CACHE_MESH_SPEC_KEY}
                            upload={true}
                        />
                        <EditPage
                            path="meshspec/new/from/:projectId"
                            CPAS_API_SERVICE_URI={CPAS_API_SERVICE_URI}
                            CACHE_MESH_SPEC_KEY={CACHE_MESH_SPEC_KEY}
                        />
                        <EditPage
                            path="meshspec/new"
                            CPAS_API_SERVICE_URI={CPAS_API_SERVICE_URI}
                            CACHE_MESH_SPEC_KEY={CACHE_MESH_SPEC_KEY}
                        />
                        <PreviewPage
                            path="meshspec/:meshSpecId"
                            CPAS_API_SERVICE_URI={CPAS_API_SERVICE_URI}
                        />
                    </Router>
                </TooltipProvider>
            </LeafletProvider>
        </Layout>
    );
};

export default Routing;

export const query = graphql`
    query {
        site {
            siteMetadata {
                CPAS_API_SERVICE_URI
                CACHE_MESH_SPEC_KEY
            }
        }
    }
`;

/// pages

const ListPage = ({ CPAS_API_SERVICE_URI }) => {
    const currentUser = useContext(CurrentUserContext);

    const [err, setErr] = useState("");
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [idToken, setIdToken] = useState(null);
    const [isUpdateList, setUpdateList] = useState(true);
    const [meshspecs, setMeshspecs] = useState(null);
    const [deleteSpec, setDeleteSpec] = useState(null);

    const doCopyMeshspec = (specId) => {
        setProcessing(true);
        copyMeshspecs({
            cpasServiceEndpoint: CPAS_API_SERVICE_URI,
            idToken,
            specId,
        })
            .then((d) => navigate(`/meshspec/${d.data.mesh_spec_id}/edit`))
            .catch((err) => {
                setErr(err);
                setProcessing(false);
            });
    };

    const doDeleteMeshspec = () => {
        const specId = deleteSpec.mesh_spec_id;
        setDeleteSpec(null);
        setProcessing(true);
        deleteMeshspec({
            cpasServiceEndpoint: CPAS_API_SERVICE_URI,
            idToken,
            specId,
        })
            .then(() =>
                getMeshspecs({
                    cpasServiceEndpoint: CPAS_API_SERVICE_URI,
                    idToken,
                })
            )
            .then((d) => setMeshspecs(d.data || null))
            .catch((err) => {
                setErr(err);
            })
            .finally(() => setProcessing(false));
    };

    const LoadNextList = (next) => {
        setUpdateList(false);
        getMeshspecs({
            cpasServiceEndpoint: CPAS_API_SERVICE_URI,
            idToken: idToken,
            next: next,
        })
            .then((d) => {
                d.data.mesh_spec_list.forEach((e) => {
                    meshspecs.mesh_spec_list.push(e);
                });
                meshspecs.next = d.data.next;
            })
            .catch((err) => setErr(err))
            .finally(() => setUpdateList(true));
    };

    useEffect(() => {
        window.sessionStorage.removeItem("redirectAfterLogin");
        currentUser
            .getIdToken()
            .then((token) => {
                if (!token) {
                    setLoading(false);
                    return;
                }
                setIdToken(token);
                getMeshspecs({
                    cpasServiceEndpoint: CPAS_API_SERVICE_URI,
                    idToken: token,
                })
                    .then((d) => {
                        setMeshspecs(d.data || null);
                    })
                    .catch((err) => setErr(err))
                    .finally(() => setLoading(false));
            })
            .catch((err) => {
                setLoading(false);
                console.error(err);
            });
    }, [CPAS_API_SERVICE_URI, currentUser]);

    const actionBtnCss =
        "rounded-full bg-white p-2 text-black hover:text-green1";

    const LoadMoreMeshspecAction = ({ next }) => (
        <div className="flex flex-row items-center justify-around px-4">
            <button
                className={actionBtnCss}
                aria-label="Load More"
                onClick={() => LoadNextList(next)}
                title="Load More"
            >
                <p>Load More</p>
            </button>
        </div>
    );

    const LoadMoreMeshspecView = ({ next }) => (
        <div className="flex items-stretch w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <div className="flex flex-1 items-center justify-center relative p-8 border border-light-grey rounded-lg">
                {isUpdateList ? (
                    <div>
                        <p>Load More</p>
                        <div className="absolute inset-0 flex flex-col opacity-0 hover:opacity-95 bg-lightgreen1 transit-opacity">
                            <div className="flex-1" />
                            <LoadMoreMeshspecAction next={next} />
                            <div className="flex-1" />
                        </div>
                    </div>
                ) : (
                    <div className="absolute inset-0 flex flex-col items-center justify-center">
                        <div className="flex items-center justify-center">
                            <i className="fas fa-spinner fa-spin fa-fw fa-2x"></i>
                        </div>
                        <div className="text-sm mt-4">Loading...</div>
                    </div>
                )}
            </div>
        </div>
    );

    const AddMeshspecAction = () => (
        <div className="flex flex-row items-center justify-around px-4">
            <button
                className={actionBtnCss}
                aria-label="Add new mesh spec"
                onClick={() => navigate("/meshspec/new")}
                title="New"
            >
                <i className="fas fa-file fa-fw" />
            </button>
            <button
                className={actionBtnCss}
                aria-label="Upload to create new mesh spec"
                onClick={() => navigate("/meshspec/new/fromUpload")}
                title="Import"
            >
                <i className="fas fa-upload fa-fw" />
            </button>
        </div>
    );

    const AddMeshspecView = () => (
        <div className="flex items-stretch w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <div className="flex flex-1 items-center justify-center relative p-8 border border-light-grey rounded-lg">
                <i className="fas fa-plus fa-3x fa-fw text-grey-light" />
                <div className="absolute inset-0 flex flex-col opacity-0 hover:opacity-95 bg-lightgreen1 transit-opacity">
                    <div className="flex-1" />
                    <AddMeshspecAction />
                    <div className="flex-1" />
                </div>
            </div>
        </div>
    );

    const MeshspecView = ({ spec }) => (
        <div className="flex items-stretch w-full sm:w-1/2 md:w-1/3 lg:w-1/4 p-2">
            <div className="flex-1 relative p-8 border border-light-grey rounded-lg h-32">
                <div className="absolute inset-0 flex flex-col items-center justify-center p-4">
                    <div className="w-full truncate text-center m-2">
                        {spec.name}
                    </div>
                    <div className="flex flex-wrap text-sm text-grey-darker m-2">
                        <div className="pr-1">Last Updated:</div>
                        {moment
                            .utc(spec.last_updated_at)
                            .format("YYYY/M/D HH:mm:ss")}{" "}
                        UTC
                    </div>
                </div>
                <div className="absolute inset-0 flex flex-col opacity-0 hover:opacity-95 bg-lightgreen1 transit-opacity">
                    <div className="flex-1" />
                    <MeshspecActions spec={spec} />
                    <div className="flex-1" />
                </div>
            </div>
        </div>
    );

    const MeshspecActions = ({ spec }) => (
        <div className="flex flex-row items-center justify-around px-4">
            <button
                className={actionBtnCss}
                onClick={() => navigate(`/meshspec/${spec.mesh_spec_id}/edit`)}
                aria-label={"Edit " + spec.name}
                title="Edit"
            >
                <i className="fas fa-pen fa-fw" />
            </button>
            <button
                className={actionBtnCss}
                onClick={() => {
                    doCopyMeshspec(spec.mesh_spec_id);
                }}
                aria-label={"Clone " + spec.name}
                title="Clone"
            >
                <i className="fas fa-copy fa-fw" />
            </button>
            <button
                className={actionBtnCss}
                onClick={() => setDeleteSpec(spec)}
                aria-label={"Delete " + spec.name}
                title="Delete"
            >
                <i className="fas fa-trash fa-fw" />
            </button>
            <button
                className={actionBtnCss}
                onClick={() => navigate(`/meshspec/${spec.mesh_spec_id}`)}
                aria-label={"Open " + spec.name + " preview"}
                title="Open preview"
                disabled={spec.status === "PENDING"}
            >
                <i className="fas fa-image fa-fw" />
            </button>
        </div>
    );

    return (
        <>
            <Seo title="My MeshSpecs" />
            <main
                className={
                    "flex flex-col flex-grow " +
                    (loading || processing ? " h-screen" : "h-full")
                }
            >
                <div className="flex flex-row items-stretch bg-grey-light h-12">
                    <div className="flex flex-row items-center flex-1 ml-4">
                        Mesh Specification
                    </div>
                </div>
                {err && <ErrorStickyTop message={err} />}

                {loading || processing ? (
                    <Loading />
                ) : isUpdateList || idToken ? (
                    <div className="flex flex-wrap m-16 items-stretch">
                        <AddMeshspecView />
                        {!!meshspecs &&
                            meshspecs.mesh_spec_list.length !== 0 &&
                            meshspecs.mesh_spec_list.map((spec, idx) => (
                                <MeshspecView key={idx} spec={spec} />
                            ))}
                        {!!meshspecs && meshspecs.next ? (
                            <LoadMoreMeshspecView next={meshspecs.next} />
                        ) : (
                            <div />
                        )}
                    </div>
                ) : (
                    <SignInAgain />
                )}

                {deleteSpec && (
                    <ConfirmBox
                        title={`Are you sure to delete the mesh specification '${deleteSpec.name}'?`}
                        deleteAction={() => doDeleteMeshspec()}
                        cancelAction={() => setDeleteSpec(null)}
                    />
                )}
            </main>
        </>
    );
};

const EditPage = ({
    meshSpecId,
    projectId,
    CPAS_API_SERVICE_URI,
    CACHE_MESH_SPEC_KEY,
    upload,
}) => {
    const currentUser = useContext(CurrentUserContext);
    const leaflet = useContext(LeafletWebComponentsContext);
    const tooltip = useContext(TooltipWebComponentsContext);

    const [view, setView] = useState(<Loading />);
    const [idToken, setIdToken] = useState(null);

    useEffect(() => {
        currentUser.getIdToken().then((idToken) => {
            setIdToken({ idToken });
        });
        window._estimateCellsFunc = (spec, ports) => {
            if (
                spec &&
                spec.regional_options &&
                spec.regional_options.features
            ) {
                spec.regional_options.features = spec.regional_options.features.filter(
                    (f) => {
                        return f.id && f.properties;
                    }
                );
            }
            estimate(spec).then((res) => {
                if (res.err) {
                    console.error("Estimate cell error:", res.err);
                }
                ports.estimateCells.send([
                    [res.min, res.max, res.err],
                    res.geoFeatures,
                ]);
            });
        };
    }, [currentUser]);

    const parseGeoJsonCoords = (geojson) => {
        if (!geojson) {
            return [];
        }
        return geojson.features.map((feature) => {
            return feature.geometry.coordinates;
        });
    };

    const parseGeoJson = (geojson) => {
        if (!geojson) {
            return {};
        }
        var collection = geojson[0];
        var properties = geojson[1];
        var geo = collection.features.map((feature) => {
            Object.assign(feature.properties, properties);
            return feature;
        });
        return geo[0];
    };

    useEffect(() => {
        if (idToken && leaflet.ready && tooltip.ready) {
            setView(
                <>
                    <MeshStepProgressBar step={0} />
                    <Elm
                        key={JSON.stringify(idToken) + meshSpecId + "_edit"}
                        src={EditApp.Elm.Page.MeshSpec.Editor}
                        flags={{
                            cpasServiceEndpoint: CPAS_API_SERVICE_URI,
                            idToken: !!idToken.idToken ? idToken.idToken : null,
                            fromSave: meshSpecId ? meshSpecId : null,
                            fromProject: projectId ? projectId : null,
                            fromUpload: !!upload,
                            cache: JSON.parse(
                                window.localStorage.getItem(
                                    CACHE_MESH_SPEC_KEY
                                ) || "{}"
                            ),
                        }}
                        ports={(ports) => {
                            ports.gotoNewMeshSpec.subscribe(() => {
                                MapEditState.reset();
                                navigate("/meshspec/new");
                            });
                            ports.gotoEditMeshSpec.subscribe((meshSpecId) => {
                                MapEditState.reset();
                                navigate(`/meshspec/${meshSpecId}/edit`);
                            });
                            ports.gotoPreviewMesh.subscribe((meshSpecId) => {
                                MapEditState.reset();
                                navigate(`/meshspec/${meshSpecId}`);
                            });
                            ports.clearCachedMeshSpec.subscribe(() => {
                                window.localStorage.removeItem(
                                    CACHE_MESH_SPEC_KEY
                                );
                            });
                            ports.saveMeshSpecCache.subscribe((cacheValue) => {
                                // cache only for guest
                                if (!idToken.idToken) {
                                    window.localStorage.setItem(
                                        CACHE_MESH_SPEC_KEY,
                                        JSON.stringify(cacheValue)
                                    );
                                }
                            });
                            ports.regionSelected.subscribe(
                                (selectedRegionID) => {
                                    setTimeout(function () {
                                        const regionDiv = document.getElementById(
                                            selectedRegionID
                                        );
                                        // Region list is not rendered when the control options are hidden
                                        if (!regionDiv) {
                                            return;
                                        }
                                        let topPos = regionDiv.offsetTop;
                                        let listTopPos = document.getElementById(
                                            "region-list"
                                        ).offsetTop;
                                        document.getElementById(
                                            "region-list"
                                        ).scrollTop = topPos - listTopPos;
                                    }, 100);
                                }
                            );

                            ports.vertexChanged.subscribe(() => {
                                MapEditState.set();
                            });

                            ports.mapEditStop.subscribe((j) => {
                                // TODO(wkchan): Use a better representation for j instead of tuple
                                if (
                                    JSON.stringify(parseGeoJsonCoords(j[0])) ===
                                    JSON.stringify(parseGeoJsonCoords(j[1]))
                                ) {
                                    MapEditState.reset();
                                } else {
                                    MapEditState.set();
                                }
                            });

                            ports.meshSaved.subscribe(() => {
                                MapEditState.reset();
                            });

                            ports.geoJsonUpdated.subscribe((spec) => {
                                if (window._estimateCellsTimeout) {
                                    clearTimeout(window._estimateCellsTimeout);
                                }
                                window._estimateCellsTimeout = setTimeout(
                                    (spec, ports) => {
                                        window._estimateCellsFunc(spec, ports);
                                    },
                                    500,
                                    spec,
                                    ports
                                );
                            });

                            ports.copyGeoJson.subscribe((gjson) => {
                                let selBox = document.createElement("textarea");
                                selBox.style.position = "fixed";
                                selBox.style.left = "0";
                                selBox.style.top = "0";
                                selBox.style.opacity = "0";
                                selBox.value = JSON.stringify(
                                    parseGeoJson(gjson)
                                );
                                document.body.appendChild(selBox);
                                selBox.focus();
                                selBox.select();
                                document.execCommand("copy");
                                document.body.removeChild(selBox);
                            });
                        }}
                    />
                </>
            );
        }
    }, [
        idToken,
        leaflet,
        tooltip,
        meshSpecId,
        projectId,
        CPAS_API_SERVICE_URI,
        CACHE_MESH_SPEC_KEY,
        upload,
    ]);

    useEffect(() => {
        MapEditState.reset();
        window.onbeforeunload = function () {
            if (MapEditState.isEdited()) {
                return "Do you want to save mesh editing before leaving the page?";
            }
            return;
        };
    });

    return (
        <>
            <Seo title="Edit MeshSpec" />
            {view}
        </>
    );
};

const PreviewPage = ({ meshSpecId, CPAS_API_SERVICE_URI }) => {
    const currentUser = useContext(CurrentUserContext);
    const leaflet = useContext(LeafletWebComponentsContext);

    const [view, setView] = useState(<Loading />);
    const [idToken, setIdToken] = useState(null);

    useEffect(() => {
        currentUser.getIdToken().then((idToken) => {
            setIdToken({ idToken });
        });
    }, [currentUser]);

    useEffect(() => {
        if (idToken && idToken.idToken && leaflet.ready) {
            setView(
                <>
                    <FileResourceAccessProvider idToken={idToken.idToken}>
                        <MeshStepProgressBar step={1} />
                        <Elm
                            key={
                                JSON.stringify(idToken) +
                                meshSpecId +
                                "_preview"
                            }
                            src={PreviewApp.Elm.Page.MeshSpec.Preview}
                            flags={{
                                cpasServiceEndpoint: CPAS_API_SERVICE_URI,
                                idToken: idToken.idToken,
                                meshSpecId: meshSpecId,
                            }}
                            ports={(ports) => {
                                ports.gotoSignup.subscribe(() => {
                                    navigate(`/signup`);
                                });
                                ports.edit.subscribe((meshSpecId) => {
                                    navigate(`/meshspec/${meshSpecId}/edit`);
                                });
                                ports.order.subscribe(() => {
                                    navigate(`/order/s/${meshSpecId}`);
                                });
                                ports.back.subscribe(() => {
                                    window.history.back();
                                });
                                ports.refresh.subscribe(() => {
                                    window.location.reload();
                                });
                            }}
                        />
                    </FileResourceAccessProvider>
                </>
            );
        } else if (idToken && leaflet.ready) {
            setView(
                <>
                    <MeshStepProgressBar step={1} />
                    <Elm
                        key={JSON.stringify(idToken) + meshSpecId + "_preview"}
                        src={PreviewApp.Elm.Page.MeshSpec.Preview}
                        flags={{
                            cpasServiceEndpoint: CPAS_API_SERVICE_URI,
                            idToken: null,
                            meshSpecId: meshSpecId,
                        }}
                        ports={(ports) => {
                            ports.gotoSignup.subscribe(() => {
                                navigate(`/signup`);
                            });
                            ports.edit.subscribe((meshSpecId) => {
                                navigate(`/meshspec/${meshSpecId}/edit`);
                            });
                            ports.order.subscribe(() => {
                                navigate(`/order/s/${meshSpecId}`);
                            });
                            ports.back.subscribe(() => {
                                window.history.back();
                            });
                        }}
                    />
                </>
            );
        }
    }, [idToken, leaflet, meshSpecId, CPAS_API_SERVICE_URI]);

    return (
        <>
            <Seo title="Preview Mesh" />
            {view}
        </>
    );
};
