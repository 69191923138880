const turf = require("@turf/turf");

class Region {
    /**
    This class handles each region options created by the user.
    -------------------------------------
    Parameters:
        ptsArr : (Nx2) 2-dimensional numeric arrays
        tgResol: numeric
    */
    constructor(ptsArr, tgResol, orogResol, coastResol) {
        this.ptsArr = ptsArr; // 2D; eg. [[125, -15], [113, -22], [154, -27], [144, -15], [125, -15]]
        this.tgResol = tgResol; // in km
        this.orogResol = orogResol;
        this.coastResol = coastResol;
        this.polygon = turf.polygon([ptsArr]);
        let international_date_line1 = [
            [
                [-180.00001, -90],
                [-180.00001, 90],
                [-179.99999, 90],
                [-179.99999, -90],
                [-180.00001, -90],
            ],
        ];
        let international_date_line2 = [
            [
                [180.00001, -90],
                [180.00001, 90],
                [179.99999, 90],
                [179.99999, -90],
                [180.00001, -90],
            ],
        ];
        this.international_date_polygon1 = turf.polygon(
            international_date_line1
        );
        this.international_date_polygon2 = turf.polygon(
            international_date_line2
        );

        let leftOutOfBound = [
            [
                [-180, -90],
                [-180, 90],
                [-540, 90],
                [-540, -90],
                [-180, -90],
            ],
        ];
        let rightOutOfBound = [
            [
                [180, -90],
                [180, 90],
                [540, 90],
                [540, -90],
                [180, -90],
            ],
        ];
        this.leftOutOfBound = turf.polygon(leftOutOfBound);
        this.rightOutOfBound = turf.polygon(rightOutOfBound);

        this.polygon = this.divideAcrossIntLine(this.polygon);
    }

    divideAcrossIntLine(polygons) {
        /* This function returns the polygons across the international lines;
           and translates them within [-180, 180] longitude. */
        polygons = turf.difference(polygons, this.international_date_polygon1);
        polygons = turf.difference(polygons, this.international_date_polygon2);
        let unionPolygon = null;
        if (
            polygons.geometry.type === "MultiPolygon" ||
            polygons.geometry.type === "Polygon"
        ) {
            let flatten_polygons = turf.flatten(polygons).features;
            //let c = extended_polygons.geometry.coordinates;
            for (var i = 0; i < flatten_polygons.length; i++) {
                let p = flatten_polygons[i];
                // Translate the sub-polygon if it is out of the bounding box
                if (turf.booleanWithin(p, this.leftOutOfBound)) {
                    for (var j = 0; j < p.geometry.coordinates[0].length; j++) {
                        p.geometry.coordinates[0][j][0] += 360;
                    }
                } else if (turf.booleanWithin(p, this.rightOutOfBound)) {
                    for (j = 0; j < p.geometry.coordinates[0].length; j++) {
                        p.geometry.coordinates[0][j][0] -= 360;
                    }
                }
                p = turf.rewind(p);
                // Union the sub-polygons in the MultiPolygon into one
                if (unionPolygon == null) {
                    unionPolygon = p;
                } else {
                    unionPolygon = turf.union(unionPolygon, p);
                }
            }
        } else {
            unionPolygon = polygons;
        }
        return unionPolygon;
    }

    getEnlargedPolygon(addDist) {
        let extended_polygons = turf.buffer(this.polygon, addDist);
        extended_polygons = this.divideAcrossIntLine(extended_polygons);
        return extended_polygons;
    }
}

class RegionsSet {
    /**
    This class handles thw whole  mesh with some polygon features and other options by the user.
    -------------------------------------
    Parameters:
       mesh_json: the JSON object of the whole mesh

    **/
    constructor(meshSpecJson, divisor = 50) {
        let meshObj = JSON.parse(String(meshSpecJson));
        this.bboxEarth = turf.bboxPolygon([-180, -90, 180, 90]);
        this.surfaceAreaEarth = turf.area(this.bboxEarth) / 1000.0 / 1000.0;
        this.radiusEarth = Math.sqrt(this.surfaceAreaEarth / 4 / Math.PI);

        this.coarstResol = meshObj.global_options.coarsest_resolution_km;
        this.finestResol = this.coarstResol; // initialization first
        this.gradient = meshObj.global_options.max_resolution_gradient;
        this.hasLimitedDomain = false;
        this.boundaryDomain = turf.bboxPolygon([0, 0, 0, 0]);
        this.regionPolygon = []; // [polygon1, polygon2, ...]
        this.resolArr = [];
        this.resolArrforFinest = [];

        if (meshObj.hasOwnProperty("boundary_options")) {
            let boundariesFeatures = meshObj.boundary_options.features;
            for (let i = 0; i < boundariesFeatures.length; i++) {
                let f = boundariesFeatures[i];
                let coords = f.geometry.coordinates[0];
                let r = new Region(coords, null, null, null);
                let p = r.divideAcrossIntLine(r.polygon);
                this.boundaryDomain = turf.union(this.boundaryDomain, p);
            }
            this.boundaryDomain = this.rewind(this.boundaryDomain);
            if (boundariesFeatures.length > 0) {
                this.hasLimitedDomain = true;
            }
        }

        if (meshObj.hasOwnProperty("regional_options")) {
            let regionsFeatures = meshObj.regional_options.features;
            // find finest resolution
            for (let i = 0; i < regionsFeatures.length; i++) {
                let f = regionsFeatures[i];
                let resol = f.properties.resolution_km;
                let orogResol = null;
                let coastResol = null;
                // round off the target resolution to 2^i
                /*
                if (resol != this.finestResol){
                    resol = Math.pow(2, Math.round(Math.log2(resol/this.finestResol))) * this.finestResol;
                }
                */
                // assign the finest resolution of the whole mesh
                if (f.properties.resolution_km < this.finestResol) {
                    this.finestResol = f.properties.resolution_km; // assignment of finest resolution
                }

                let p = f.properties;
                if (p.hasOwnProperty("boost_resolution_for_orography")) {
                    orogResol =
                        p.boost_resolution_for_orography.finest_resolution_km;
                    if (orogResol < this.finestResol) {
                        this.finestResol = orogResol;
                    }
                }
                if (p.hasOwnProperty("boost_resolution_for_coastline")) {
                    coastResol =
                        p.boost_resolution_for_coastline.finest_resolution_km;
                    if (coastResol < this.finestResol) {
                        this.finestResol = coastResol;
                    }
                }
                this.regionPolygon.push(
                    new Region(
                        f.geometry.coordinates[0],
                        resol,
                        orogResol,
                        coastResol
                    )
                );
                //this.resolArr.push(resol);
            }

            // Take square root to this.coarstResol and this.finestResol; then equally divide the range
            this.INCREAMENT_IN_RESOL =
                (Math.sqrt(this.coarstResol) - Math.sqrt(this.finestResol)) /
                divisor; //in km
            for (
                var sqrtResol = Math.sqrt(this.finestResol);
                sqrtResol < Math.sqrt(this.coarstResol);
                sqrtResol += this.INCREAMENT_IN_RESOL
            ) {
                let createdResol = Math.pow(sqrtResol, 2);
                createdResol =
                    Math.pow(
                        2,
                        Math.round(Math.log2(createdResol / this.finestResol))
                    ) * this.finestResol;
                this.resolArr.push(createdResol);
            }
            //this.resolArr.push(this.coarstResol);
            const distinct = (value, index, self) => {
                return self.indexOf(value) === index;
            };
            this.resolArr = this.resolArr.filter(distinct);
            this.resolArr.sort((a, b) => a - b); //reduce method
        }
    }

    rewind(polygons) {
        if (polygons != null) {
            return turf.rewind(polygons);
        } else {
            return polygons;
        }
    }

    getResolContour(resolLevel, fillAllfinest = true) {
        /**
         * Return the geojson of the polygons that the resolution is less than
        'resol' inside it.
        resolLevel: numeric;
        fillAllfinest:  boolean; 'True' represents that the target resolution of a drawn region is
                      the minimum of its target resolution, orography resolution and coastline resolution;

        **/
        let unionPolygon = null;
        if (this.regionPolygon.length === 0) {
            return unionPolygon;
        }
        for (let i = 0; i < this.regionPolygon.length; i++) {
            let r = this.regionPolygon[i];
            // if the target resol. of the polygon is coarser  than the cuurent resolLevel
            // then do not merge it to 'unionPolygon'
            let tgResol = r.tgResol;
            if (fillAllfinest) {
                tgResol = r.tgResol;
                if (r.orogResol != null) {
                    tgResol = Math.min(tgResol, r.orogResol);
                }
                if (r.coastResol != null) {
                    tgResol = Math.min(tgResol, r.coastResol);
                }
            } else {
                tgResol = r.tgResol;
            }
            if (resolLevel >= tgResol) {
                let extendedDist = (resolLevel - tgResol) / this.gradient;
                if (unionPolygon == null) {
                    unionPolygon = r.getEnlargedPolygon(extendedDist);
                } else {
                    unionPolygon = turf.union(
                        unionPolygon,
                        r.getEnlargedPolygon(extendedDist)
                    );
                }
            }
        }
        // rewind the polygon outer ring counterclockwise and inner rings clockwise
        unionPolygon = this.rewind(unionPolygon);

        // cut the unionPolygon that all the things are within the domain boundaries
        if (this.hasLimitedDomain) {
            //let diff = turf.difference(unionPolygon, this.boundaryDomain);
            //unionPolygon = turf.difference(unionPolygon, diff);
            unionPolygon = turf.intersect(this.boundaryDomain, unionPolygon);
            unionPolygon = this.rewind(unionPolygon);
            unionPolygon = this.divideAcrossIntLine(unionPolygon);
            unionPolygon = this.rewind(unionPolygon);
        }
        return unionPolygon;
    }

    getAreas(fillAllfinest = true) {
        let finerResol = this.finestResol;
        let coarserResol = this.finestResol; // just initialization
        let prevArea = 0;
        let embArea = 0;
        let area = 0;
        let regionObj = [];
        for (let i = 0; i < this.resolArr.length; i++) {
            let resol = this.resolArr[i];
            let unionPolygon = null;
            if (resol >= this.coarstResol) {
                unionPolygon = this.getResolContour(
                    this.coarstResol,
                    fillAllfinest
                );
                coarserResol = this.coarstResol;
            } else {
                unionPolygon = this.getResolContour(resol, fillAllfinest);
                coarserResol = resol;
            }
            if (unionPolygon == null) {
                continue;
            }
            // Push the area of the current resolution level
            embArea = turf.area(unionPolygon) / 1000.0 / 1000.0; // Area is calculated in km^2
            area = embArea - prevArea;
            regionObj.push({
                finerResol: finerResol,
                coarserResol: coarserResol,
                area: area,
                LowBoundNCells: area / this.resolToArea(coarserResol),
                unionPolygon: unionPolygon,
            });
            // next step
            finerResol = resol;
            prevArea = embArea;
        }
        // Push the area outer the "coarst region" of the remaining part of the earth
        area = this.surfaceAreaEarth - prevArea;
        regionObj.push({
            finerResol: this.coarstResol,
            coarserResol: this.coarstResol,
            area: area,
            LowBoundNCells: area / this.resolToArea(this.coarstResol),
            unionPolygon: this.bboxEarth,
        });
        return regionObj;
    }

    estimateLowboundNcells() {
        let estimatedCellsObj = [];
        let accNCells = 0;
        let regionObj = this.getAreas(false);
        for (let i = 0; i < regionObj.length; i++) {
            let a = regionObj[i];
            let NCells = a.LowBoundNCells;
            accNCells += NCells;
            estimatedCellsObj.push({
                finerResol: a.finerResol,
                coarserResol: a.coarserResol,
                area: a.area,
                NCells: NCells,
                accNCells: accNCells,
                polygon: a.unionPolygon ? a.unionPolygon.geometry : null,
            });
        }
        return estimatedCellsObj;
    }

    estimateUpboundNcells() {
        let estimatedCellsObj = [];
        let accNCells = 0;
        let regionObjForFinest = this.getAreas(true);
        for (let i = 0; i < regionObjForFinest.length; i++) {
            let a = regionObjForFinest[i];
            let NCells = a.area / this.resolToArea(a.finerResol);
            accNCells += NCells;
            estimatedCellsObj.push({
                finerResol: a.finerResol,
                coarserResol: a.coarserResol,
                area: a.area,
                NCells: NCells,
                accNCells: accNCells,
            });
        }
        return estimatedCellsObj;
    }

    resolToArea(resol) {
        if (this.regionPolygon.length === 0) {
            // for quasiuniform case
            let mpas_radiusEarth = 6371.229;
            let m = Math.round((Math.atan(2) * mpas_radiusEarth) / resol);
            m = Math.max(m, 1);
            let areaEarth = 4 * Math.PI * Math.pow(mpas_radiusEarth, 2);
            let avgAreaCell = areaEarth / (10 * Math.pow(m, 2) + 2); // in km^2
            return avgAreaCell;
        } else {
            let center = turf.point([0, 0]);
            let mpas_radiusEarth = 6371.229;
            let hexRadii = Math.atan(
                Math.tan(resol / 2 / mpas_radiusEarth) / Math.cos(Math.PI / 6)
            );
            hexRadii *= mpas_radiusEarth;
            let hexagonPts = [];
            for (let bearing = -180; bearing <= 180; bearing += 60) {
                hexagonPts.push(
                    turf.destination(center, hexRadii, bearing).geometry
                        .coordinates
                );
            }
            hexagonPts.push(hexagonPts[0]); // to end the circuit of the polygon
            return turf.area(turf.polygon([hexagonPts])) / 1000.0 / 1000.0; // in km^2
        }
    }
}

const estimate = async (meshSpec) => {
    return new Promise((resolve, reject) => {
        try {
            let rs = new RegionsSet(JSON.stringify(meshSpec));
            let minCells = rs.estimateLowboundNcells();
            let maxCells = rs.estimateUpboundNcells();
            resolve({
                min: parseInt(minCells[minCells.length - 1].accNCells),
                max: parseInt(maxCells[maxCells.length - 1].accNCells),
                geoFeatures: minCells
                    .filter((c) => {
                        return c.polygon;
                    })
                    .map((c) => {
                        return {
                            geometry: c.polygon,
                            properties: {
                                cells: parseInt(c.accNCells),
                                resolution: c.coarserResol,
                            },
                        };
                    }),
                err: "",
            });
        } catch (err) {
            resolve({ min: -1, max: -1, err: err.toString() });
        }
    });
};

export default estimate;
