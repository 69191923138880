import React from "react";

const confirmBox = ({
    title,
    deleteTxt = "Delete",
    cancelTxt = "Cancel",
    deleteAction,
    cancelAction,
}) => (
    <div>
        <div class="confirm-box" />
        <div class="confirm-body">
            <div class="border-grey-darkest shadow-lg rounded lg:w-1/2">
                <div class="bg-green1 rounded-t">
                    <h3 class="inline-block p-2 text-white text-lg">
                        Confirmation
                    </h3>
                </div>
                <div class="bg-white py-4 px-8 rounded-b">
                    <div>{title}</div>
                    <div class="mt-4 flex flex-row items-center justify-around">
                        <div>
                            <button
                                class="btn del hover:bg-red-dark"
                                onClick={deleteAction || (() => {})}
                            >
                                {deleteTxt}
                            </button>
                        </div>
                        <div>
                            <button
                                class="plain btn"
                                onClick={cancelAction || (() => {})}
                            >
                                {cancelTxt}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export const ConfirmBox = confirmBox;
